@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Open+Sans:wght@400;600&family=Roboto:wght@400;500&display=swap');

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700; /* Bold for headings */
}

/* Paragraphs */
p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400; /* Regular for body text */
}



@media (max-width: 991px) {
    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
    }
    .ml-auto {
      margin-top: 10px;
    }
  }

  @keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }  


body{
  margin: 0;
  padding: 0;
  width: 100%;
}



.site-nav{
    padding-right: 50px;
    padding-left: 50px;
    background-color: white;
    padding-top: 0;
    padding-bottom: 0;
    position: sticky; /* or fixed, depending on your need */
  top: 0; /* Ensures the navbar sticks to the top */
  z-index: 3;
  font-family: sans-serif;
}

.nav-link {
    color: black; /* Change to desired color */
    font-weight: bold;
    margin: 0 10px;
    cursor: pointer;
  }

  
.nav-link:hover {
    color: #0056b3; /* Change to desired hover color */
    font-size: 17px;
}

.nav-button{
    color: white;
}

.navbar-brand img{
  width: 150px;
  height: 120px;
}

.section {
    padding: 0;
    border-bottom: 1px solid #ddd;
    height: 100vh;
  }
  
  #home {
    background-color: #f8f9fa;
    padding-top: 100px;
    padding-left: 100px;
    background-image: url('/public/images/home-img.jpg');
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  height: 100vh;
  position: relative;
  z-index: 2;
  height: auto;
  }

  /* Dark overlay using a pseudo-element */
#home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay with 50% opacity */
  z-index: 1; /* Ensure overlay is above the background image */
  pointer-events: none; /* Ensure the overlay does not interfere with mouse events */
}
#home > * {
  position: relative;
  z-index: 2; /* Content above the overlay */
  padding: 20px; /* Adjust padding as needed */
}
  
  #features {
    background-color: white;
    text-align: center;
    padding: 50px 0;
    height: auto;
  }
  
  #pricing {
    height: auto;
  }
  
  #testimonials{
    background-color: #0038ab;
  }

  #contact {
    background-color: white;
  }
  
  #features h2{
    margin-bottom: 100px;
    font-size: 25px;
    color: #0056b3;
    font-weight: 700;
    margin-top: 50px;
  }

  .feature-box{
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 400px;
  }

  .feature-box img{
    height: 100px;
    width: 100px;
    margin-bottom: 20px;
  }

  .feature-box p{
    font-size: 18px;
    max-width: 300px;
  }

.feature-box h3{
  font-size: 16px;
  color: #16163F;
  font-weight: 700;
}

.feature-row-2{
  margin-top: 40px;
}

.info-details {
  background-color: #0056b3;
  color: #fff;
  padding:  0;
  display: flex;
  align-items: center;
  margin: 0;
  height: 600px;
}

.info-details h2 {
  margin-bottom: 40px;
  color: #fff;
  font-weight: 800;
}

.info-details h3 {
  font-size: 6vw;
  margin-bottom: 10px;
  font-weight: 800;
}

.info-details p {
  margin: 0;
}

.about-us{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  padding: 100px;
  padding-top: 150px;
}

.about-us h2{
  color: #0056b3;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}

.about-us h3{
  font-size: 30px;
  color: #16163F;
  margin-bottom: 40px;
  font-weight: bold;
}

.about-us p{
  font-size: 18px;
  max-width: 500px;
  text-align: justify;
}

.about-us-img{
  background-image: url('/public/images/about-img.png');
  background-size: cover; /* Ensures the image covers the entire element */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}


.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
  min-height: 700px;
}

#home h1 {
  font-size: 70px; /* Responsive font size based on viewport width */
  margin-bottom: calc(0.1rem + 2vw); /* Responsive margin */
  font-weight: 800;
  color: white;
  padding-top: 100px;
}

#home p {
  font-size: 20px; /* Responsive font size based on viewport width */
  max-width: 90%; /* Responsive max-width */
  margin-bottom: calc(2rem + 5vw); /* Responsive margin */
  text-align: justify;
  max-width: 600px;
  color: #FFFFFF;
  font-weight: lighter;
}

#home button{
  height: 60px;
  width: 200px;
  border-radius: 20px;
  border: none;
  color: white;
  font-weight: bold;
  background-color: #0038ab;
  margin-left: 19px;
  margin-bottom: 250px;
}

footer{
  height: auto;
  color: white;
  background-color: #0038ab;
  padding: 30px;
}

footer p{
  margin: 0 auto;
  width: fit-content;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

#contact {
  padding: 200px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-image: url(/public/images/pattern.png);
  background-size: 50%;
  background-repeat: repeat;
}

.contact-left {
  padding-right: 50px;
}

.contact-left h2 {
  color: #0056b3;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-left h3 {
  font-size: 30px;
  color: #16163F;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-left p {
  font-size: 18px;
  max-width: 400px;
  margin-bottom: 60px;
}

.social-icons a {
  font-size: 26px;
  margin-right: 10px;
  color: #000;
}

.mobile-number p{
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #0056b3;
}

.contact-right h4{
  color: white;
  border-radius: 8px;
  background-color: #0038ab;
  width: fit-content;
  padding: 7px;
  margin-bottom: 15px;
  font-weight: bolder;
}


.contact-right .form-group {
  margin-bottom: 20px;
}


.contact-right .form-group label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-right .form-control {
  border-radius: 0;
}

.contact-right button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}

.contact-right p {
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}


@media (max-width: 768px) {
  .contact-left, .contact-right {
    padding: 20px;
  }

  #home{
    padding-left: 20px;
    height: auto;
    padding-bottom: 50px;
  }

  .about-us{
    padding-left: 50px;
  }
}

#map {
  margin-right: 0;
  height: 400px;
}

.map-container {
  width: 100%;
  overflow: hidden;
  height: 100%;
}

iframe {
  width: 100%;
  border: 0;
  margin: 0;
}

.site-nav .navbar-collapse {
  text-align: left;
}


.carousel{
  height: 400px;
  padding: 100px;
  margin-bottom: 50px;
}


#testimonials{
  padding-top: 80px;
  height: auto;
}

#testimonials h2{
  font-weight: bold;
  font-size: 25px;
  color: white;
  text-align: center;
  margin-bottom: 50px;
}
/* App.css */

.testimonial-carousel {
  background-color: #0038ab;
  color: white;
  padding: 30px 20px;
  text-align: center;
}

.testimonial-item {
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: center;
}

.testimonial-carousel p{
  font-size: 45px;
  max-width: 900px;
  line-height: 50px;
  padding-bottom: 10px;
  display: block;
  margin: 0 auto;
  font-weight: bolder;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: white;
  width: 1000px;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; /* Bold for headings */
}

/* Paragraphs */
p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400; /* Regular for body text */
}

/* Uniform Font Sizes for Other Sections */
h2 {
  font-size: 1.5rem; /* Uniform size for h2 */
  color: #0056b3; /* Adjust color if needed */
}

h3 {
  font-size: 1.25rem; /* Uniform size for h3 */
}

p {
  font-size: 1rem; /* Uniform size for p */
}

/* Specific Section Styles */
#features h2, #pricing h2, #testimonials h2, .about-us h2 {
  font-size: 1.5rem; /* Uniform size for h2 */
}

.feature-box h3 {
  font-size: 1.25rem; /* Uniform size for h3 */
}

.testimonial-carousel p {
  font-size: 1.25rem; /* Uniform size for testimonial text */
}

/* Adjust Sizes for Smaller Screens */
@media (max-width: 768px) {
 
  h2 {
      font-size: 1.25rem; /* Adjusted size for h2 on smaller screens */
  }

  h3 {
      font-size: 1rem; /* Adjusted size for h3 on smaller screens */
  }

  p {
      font-size: 0.875rem; /* Adjusted size for p on smaller screens */
  }
}

.testimonial-item {
  font-size: 1.2em;
  line-height: 1.5em;
  text-align: center;
}

.testimonial-carousel p{
  font-size: 45px;
  max-width: 900px;
  line-height: 50px;
  padding-bottom: 10px;
  display: block;
  margin: 0 auto;
  font-weight: bolder;
}

@media (max-width: 1000px) {
  #home{
    padding: 0;
    padding-left: 20px;
  }


  .navbar-collapse {
    text-align: left !important;
  }
  .nav-link {
    text-align: left;
    width: 100%;
  }

  #contact{
    padding-left: 50px;
    padding-right: 50px;
    height: auto;
  }

  .about-us{
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
  }

  #pricing{
    height: auto;
  }

  .testimonial-carousel p{
    font-size: 30px;
    max-width: 600px;
  }

  #features{
    padding-right: 50px;
    padding-left: 50px;
  }

  .feature-row{
    width: 100% !important;
  }

  .feature-row-2{
    gap: 0;
  }

  .feature-row-2 .feature-row{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    height: 335px;
  }

  .team-col{
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (max-width: 790px){

  .about-us{
    width: 100% !important;
  }

  .about-us-img{
    width: 100% !important;
    height: 400px;
  }

  .row-no-gutters{
    height: auto !important;
  }
}


@media (max-width: 590px){
  #home h1{
    font-size: 50px;
  }

  #home p{
    font-size: 20px;
  }
}

@media (max-width: 500px) {

  .feature-box{
    margin-bottom: 50px;
  }

  .feature-box:last-child{
    margin-bottom: 20px;
  }

  .site-nav{
    padding: 7px;
  }

  .site-nav img{
    width: 70px;
    height: 70px;
  }

  .feature-row-2{
    gap: 0;
  }

  .feature-row-2 .feature-box{
    margin-bottom: 0;
  }

  .testimonial-item p{
    font-size: 5vw;
  }

  .carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

#testimonials{
  max-height: 500px;
}

.info-details{
  height: 500px;
}

#contact{
  padding-left: 10px;
  padding-right: 10px;
}

.about-us{
  padding-left: 35px;
  padding-right: 35px;
}

#features{
  padding-left: 10px;
  padding-right: 10px;
}

#home button{
  width: auto;
  height: auto;
  padding: 13px;
  font-size: 12px;
}

}

@media (max-width: 400px){

  #home{
    padding-right: 10px;
  }
  #home h1{
    font-size: 35px;
  }

  #home p{
    font-size: 18px;
  }

  #home button{
    margin-bottom: 35px;
  }
}

@media (max-width: 767px) {
  .navbar-nav {
    text-align: center;
  align-self: flex-start;
  width: 100%;
  }
}


.strengths{
  color: #0056b3;
  font-weight: bolder;
  font-size: 15px;
}

#team {
  height: auto;
  padding-bottom: 100px;
  padding-top: 0;
  overflow: hidden;
}

#team h2{
  color: white;
  background-color: #0056b3;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#team img{
  width: 250px;
  height: 250px;
  border: 6px solid #0056b3;
}

.team-row{
  margin-top: 80px;
  margin-bottom: 0;
}

.team-col{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-col-inner{
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  margin: 5px;
  padding-top: 50px;
}

.team-col h3{
  margin-top: 10px;
}

.team-col p{
  max-width: 600px;
  text-align: justify;
  font-size: 16px;
  font-family: 'Open Sans';
}

.team-col h6{
  margin-bottom: 30px;
  color: #0056b3;
}


.additional-team{
  padding: 10px;
  margin: 120px;
  margin-top: 57px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.additional-team h3{
  color: #0056b3;
  margin-bottom: 15px;
}

.additional-team p{
  text-align: justify;
}

@media (max-width: 767px){

  .info-details h3{
    font-size: 10vw;
  }

  .additional-team{
    margin: 50px;
  }

}

@media (max-width: 500px){
  .additional-team{
    margin: 20px;
  }
}

 .testimonial-btn{
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;
  height: 40px;
  width: 150px;
  background-color: white;
  border-radius: 8px;
  border: none;
  font-weight: bold;
}

.testimonial-btn a{
  text-decoration: none;
  color: #0056b3;
}
